.no-product-error {
  margin: 1rem;
  color: var(--text-lighter);
  font-size: 1.2rem;
  font-weight: 500;
}

.products {
  width: 95vw;
}

.products > div {
  gap: 1.5rem;
  margin: 0 1rem;
}

@media screen and (max-width: 864px) {
  .products {
    width: 100%;
  }
}

@media screen and (max-width: 580px) {
  .products > div {
    gap: 10px;
  }
}
