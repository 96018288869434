.nav-bar {
  position: sticky;
  height: 95vh;
  min-width: 17rem;
  top: 4rem;
  display: flex;
  flex-direction: column;
}

.account-nav-bar-dark,
.account-nav-bar-light {
  padding: 1rem;
  color: var(--text-light);
}

.account-nav-bar-dark {
  color: var(--text-light);
}

.account-nav-bar-light {
  color: var(--text-dark);
}

.user-name {
  margin-top: 1.4rem;
}

.user-name > div {
  width: 2.5rem;
  height: 2.5rem;
  margin-left: 1rem;
}

.user-name,
.nav-orders,
.nav-payment-details,
.nav-addresses,
.nav-support,
.nav-settings {
  display: flex;
  align-items: center;
}

.user-name h1 {
  font-size: 1.1rem;
  margin-left: 10px;
  font-weight: 500;
}

.nav-menu {
  margin-top: 2rem;
}

.nav-menu-title {
  font-weight: 400;
  font-size: 1rem;
}

.account-nav-bar-dark .nav-menu h2,
.account-nav-bar-dark .user-name h1 {
  color: var(--text-light);
}

.account-nav-bar-light .nav-menu h2,
.account-nav-bar-light .user-name h1 {
  color: var(--text-dark);
}

.nav-orders,
.nav-payment-details,
.nav-addresses,
.nav-support,
.nav-settings {
  width: 95%;
  padding: 0.7rem 1rem;
  margin: 10px;
  margin-left: 8px;
}

.nav-orders i,
.nav-payment-details i,
.nav-addresses i,
.nav-support i,
.nav-settings i {
  margin-right: 10px;
  font-size: 1.2rem;
  color: var(--primary-color);
}

.active-nav {
  border-radius: 4px;
  outline: 1px solid var(--primary-color);
  transition: 0.3s ease-in;
}

@media screen and (max-width: 580px) {
  .nav-bar {
    position: fixed;
    z-index: 2;
    width: 18rem;
  }
}
