.search-container {
  margin: 0.5rem 0.6rem 0.5rem 1rem;
  width: 60%;
  height: 2.3rem;
  border: 1px solid var(--dark-blue-gray);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-container input {
  width: 100%;
  height: 2.1rem;
  margin: 0.4rem auto 0.4rem;
  margin-left: 0.6rem;
  font-size: 1rem;
  background-color: transparent;
  border: none;
}

.search-container input:focus {
  outline: none;
}

.search-container i {
  font-size: 1.2rem;
  padding: 0.6rem;
  margin: 0 0.6rem;
  color: var(--primary-color);
}
