.camera-animation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

.animation {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 3;
}

.loader-animation {
  background-color: rgba(0, 0, 0, 0.087);
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 5;
}

.payment-success-animation {
  position: relative;
  width: 10rem;
}

.loader-animation > div {
  width: 40rem;
  margin: auto;
  top: 50%;
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
}

#camera-dark {
  width: 20rem;
  animation: loader 2500ms ease-in-out;
}

@keyframes loader {
  0% {
    opacity: 0.4;
  }
  10% {
    opacity: 0.7;
  }
  20% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 450px) {
  .payment-success-animation {
    position: relative;
    width: 10rem;
  }
}
