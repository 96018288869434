.rating-slider-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
  justify-content: space-around;
  margin-top: 1rem;
}

.rating .range-input {
  width: 12rem;
  margin: 10px auto;
}
