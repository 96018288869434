.desktop-navigation-bar {
  box-shadow: 0 1px 4px var(--navbar-shadow);
}

.profile-hover-menu {
  background-color: var(--primary-color);
  width: 8.7rem;
  position: absolute;
  top: 4rem;
  right: 2.5rem;
  border-radius: 4px;
  padding: 12px;
  z-index: 50;
}

.profile-hover-menu h2 {
  font-size: 1rem;
  text-align: center;
  margin: 5px 0;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
  padding: 5px 0;
  border: 1px solid transparent;
  color: var(--text-white);
}

.profile-hover-menu h2:hover {
  border: 1px solid var(--text-white);
  border-radius: 4px;
}
