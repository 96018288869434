@import url(https://pebblecart-v2.netlify.app/src/style.css);

:root {
  /* dark mode */
  --dark-bg-color: #141517;
  --secondary-dark-bg: #18191a;
  --dark-theme-card-bg: #1a1b1c;
  --dark-theme-card-hover: rgba(40, 40, 40, 0.666);
  --title-color: #e9e9e9;
  --sub-title-color: #cecece;
  --payment-card-hover: rgb(176, 176, 176);
  --coupon-error: rgba(252, 0, 0, 0.18);
  --coupon-success: rgba(34, 252, 0, 0.18);
  --available-coupons: rgba(97, 97, 97, 0.139);
  --navbar-shadow: rgba(0, 0, 0, 0.164);
  --dark-payment-confirm-bg: rgb(57, 57, 57);

  /* light mode */
  --light-bg-color: #f1f1f1;
  --secondary-light-bg: #e9e9e9;
  --item-overview-light-bg: #f0f0f0;
  --light-theme-category-card: #24242454;
  --light-theme-card-bg: #f2f2f2;
  --light-theme-card-hover: rgba(106, 106, 106, 0.123);
  --light-theme-title-color: #252525;
  --light-theme-sub-title-color: #484848;
  --payment-card-hover: rgb(176, 176, 176);
  --banner-square-card-light: rgb(121, 146, 176);
  --order-details-bg: rgb(201, 201, 201);
  --light-payment-confirm-bg: rgb(255, 255, 255);
}

body.lightTheme {
  background-color: var(--light-bg-color);
  color: var(--light-theme-title-color);
}

.app {
  animation: app 200ms ease-in-out;
}

@keyframes app {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.4;
  }
  40% {
    opacity: 0.8;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.single-product {
  margin-bottom: 2rem;
}

.single-product-image {
  position: relative;
}

.single-product-image > button {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 1.4rem;
  padding: 10px;
  border-radius: 50%;
}

.product-description-text {
  font-size: 1.2rem;
  word-spacing: 2px;
  line-height: 26px;
  margin-bottom: 7px;
}

.rating-star-container input {
  width: 9px;
}

.notLoggedIn {
  font-size: 1.4rem;
  font-weight: 400;
  margin-top: 5rem;
  padding: 1.5rem;
  text-align: center;
  color: var(--text-white);
}

.checkout-empty-cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.checkout-empty-cart h2 {
  margin: 1rem 0;
  font-size: 1.2rem;
  margin-top: 2rem;
  font-weight: 500;
}

/* light theme start */

body.lightTheme {
  background-color: var(--light-bg-color);
  color: var(--light-theme-title-color);
}

.mobile-navigation-bar-bottom-light {
  background-color: var(--primary-bg);
}

.hero-section-light,
.landing-recommended-section-light,
.footer-light {
  background-color: var(--light-bg-color);
}

.hero-banner-light h1,
.recommended-banner-light h1 {
  color: var(--text-dark);
}

.hero-banner-light > div > p,
.recommended-banner-light > div > p {
  color: var(--text-dark);
}

.hero-section-light .card-vertical-info-light {
  background-color: var(--banner-square-card-light);
}

.hero-section-light .card-vertical-info-light:hover {
  background-color: var(--dark-theme-card-hover);
}

.hero-categories-light > a > div:hover {
  background-color: var(--light-theme-card-hover);
}

.hot-new-products-light,
.footer-light {
  background-color: var(--secondary-light-bg);
}

.hot-new-products-light > h1 {
  color: var(--text-dark);
}

.hot-new-products-light .hot-new-slider-dots > i {
  color: var(--text-dark);
}

.filter-section-light > div button {
  color: var(--light-theme-title-color);
}

.filter-section-light h2,
.products-light > h1,
.product-details-header-light,
.product-details-body-light,
.single-product-description-light,
.card-text-light h1 {
  color: var(--light-theme-title-color);
}

.filter-section-light label,
.filter-section-light p {
  color: var(--light-theme-sub-title-color);
}

.products-light > div > div:hover,
.wishlist-light > div > div:hover,
.cart-products-light > div:hover,
.similar-products-light > div > div:hover {
  background-color: var(--light-theme-card-hover);
}

.product-details-feature-light {
  color: var(--text-dark);
}

.rating-badge-light {
  color: var(--text-dark);
}

.cart-light > h1,
.wishlist-light > h1,
.similar-products-light > h1,
.copyright-text-light {
  color: var(--light-theme-title-color);
}

.hero-categories-light > a > div,
.products-light > div > div,
.wishlist-light > div > div,
.cart-item-card-light,
.similar-products-light > div > div {
  background-color: var(--light-theme-card-bg);
}

.checkout-item-card-light {
  background-color: var(--primary-bg);
  border: none;
}

.cart-item-card-light {
  background-color: var(--primary-bg);
}

.cart-item-card-light:hover {
  background-color: transparent;
}

#products-light .card-dark .card-text-light .card-price,
.cart-item-card-light .card-price,
.cart-products-light .card-price {
  color: var(--text-red);
  font-weight: 500;
}

.checkout-price-table {
  border: none;
}

.price-table-light p {
  color: var(--text-dark);
}

.contact-address-light,
.payment-method-light,
.checkout-item-overview-light {
  background-color: var(--primary-bg);
  color: var(--light-theme-sub-title-color);
}

.contact-address-light .address label {
  color: var(--light-theme-sub-title-color);
  font-size: 1rem;
}

.contact-address-light button {
  color: var(--text-dark);
  font-weight: bold;
}

.checkout-item-overview-light:hover,
.contact-address-light:hover,
.payment-method-light:hover,
.order-card-top-section:hover,
.order-card-bottom-section:hover {
  background-color: var(--light-theme-card-hover);
}

.order-card-top-section-light {
  background-color: var(--light-theme-card-hover);
  color: var(--text-dark);
}

.order-card-bottom-section-light {
  color: var(--text-dark);
  background-color: var(--order-details-bg);
}

.checkout-item-card .card-light {
  color: var(--light-theme-sub-title-color);
}

.payment-confirm-wrapper-light {
  background-color: var(--light-payment-confirm-bg);
  color: var(--light-theme-sub-title-color);
}

.break-line-light,
.section-break-line-light {
  border-bottom: 1px solid var(--text-dark);
}

.footer-light .footer-main-area,
.footer-light .footer-main-area a {
  color: var(--light-theme-sub-title-color);
}

.footer-light .footer-connect-icons-light i:hover {
  background-color: var(--light-theme-card-hover);
}

.footer-light .footer-copyright-text-light {
  color: var(--text-dark);
}

.card-light h1,
.card-light .rating-badge-number {
  color: var(--text-dark);
}

/* card img size */
.card-vertical img,
.card-horizontal img,
.cart-item-card img {
  min-width: 9.5rem;
  height: 8rem;
  object-fit: contain;
}

.card-horizontal img,
.cart-item-card img {
  width: 8rem;
}

.card-vertical-info .card-img-container {
  height: 6.5rem;
}

.filter-section {
  width: 16.8rem;
}

.single-product-image .main-image {
  width: 19rem;
  height: 19rem;
  object-fit: contain;
}

.single-product-image .main-image:hover {
  transform: scale(1.3);
  transition: 200ms ease-in;
  cursor: pointer;
}

.single-product-image-options img {
  width: 4rem;
  margin: 12px;
  cursor: pointer;
}

@media screen and (max-width: 1050px) {
  .filter-section {
    padding-top: 0;
  }

  .single-product-image .main-image {
    width: 15rem;
    height: auto;
    object-fit: contain;
  }
}

@media screen and (max-width: 864px) {
  .filter-section {
    background-color: var(--dark-theme-card-bg);
  }
  .filter-section-light {
    background-color: var(--light-theme-card-bg);
  }
}

@media screen and (max-width: 580px) {
  .hero-banner {
    margin: 3.5rem auto 2rem;
  }

  .hero-banner img {
    margin: 3rem auto 2rem;
  }

  .card-vertical img,
  .card-horizontal img,
  .cart-item-card img {
    width: 9.5rem;
    height: 8rem;
    object-fit: contain;
  }

  .card-horizontal img,
  .cart-item-card img {
    width: 9rem;
  }

  .card-vertical .card-nav {
    width: 100%;
  }

  .card-vertical .card-cta-btn {
    justify-content: space-between;
    min-width: 100%;
  }

  .single-product-detail {
    margin-top: 0;
  }

  .product-details-body {
    margin-top: 0;
  }

  .single-product-image {
    margin-bottom: 0;
  }

  .single-product-image .main-image {
    width: 14rem;
    height: 20rem;
    margin: auto;
    object-fit: contain;
  }
}

@media screen and (max-width: 400px) {
  .hero-banner {
    margin: 7rem auto 0;
  }

  .hero-banner img {
    margin: 3rem auto 2rem;
  }
}
