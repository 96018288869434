.payment-confirm-wrapper,
.payment-confirm-wrapper-light {
  background-color: var(--dark-payment-confirm-bg);
  color: var(--title-color);
  border-radius: 10px;
  padding: 0 3rem 3rem;
  max-width: 35rem;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  width: 100% !important;
}

.close-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.3rem;
}

.payment-confirm-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment-confirm-title {
  font-size: 1.6rem;
  margin: -1.2rem 0 1rem;
  text-align: center;
  font-weight: 500;
}

.payment-confirm-message {
  font-size: 1.1rem;
  font-weight: 400;
  margin-bottom: 1rem;
  text-align: center;
}

.orderId,
.paymentId {
  margin: 5px 0;
  font-size: 1.1rem;
}

.payment-confirm-cta {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-top: 2rem;
}

.payment-confirm-cta button {
  width: 100%;
  margin: 0;
}

@media screen and (max-width: 450px) {
  .payment-confirm-wrapper {
    padding: 0 1rem 2.5rem;
  }

  .payment-confirm-title {
    font-size: 1.3rem;
  }

  .payment-confirm-message {
    font-size: 1.1rem;
  }

  .orderId,
  .paymentId {
    font-size: 1.1rem;
  }

  .payment-confirm-cta {
    margin-top: 1.5rem;
  }
}
