.atm-card-wrapper {
  width: 19rem;
  height: 12rem;
  border-radius: 10px;
  padding: 10px;
  overflow: hidden;
  margin: 10px;
  position: relative;
}

.atm-card-wrapper::-webkit-scrollbar {
  display: none;
}

.atm-card-wrapper button {
  visibility: hidden;
  padding: 5px 0.8rem;
  position: absolute;
  z-index: 2;
  top: 8px;
  right: 6px;
  border-radius: 50%;
  background-color: var(--payment-card-hover);
}

.atm-card-wrapper button:hover {
  background-color: var(--light-gray);
}

.atm-card-wrapper:hover button {
  visibility: visible;
}

.card-option-menu {
  right: 1rem;
  top: 3rem;
  width: 7rem;
  height: auto;
  border-radius: 4px;
  color: var(--text-dark);
  background-color: var(--primary-bg);
  position: absolute;
  z-index: 2;
}

.card-option-menu h2 {
  font-size: 0.9rem;
  font-weight: 500;
  text-align: center;
  margin: 5px;
  padding: 4px;
  cursor: pointer;
}

.card-option-menu h2:hover {
  outline: 1px solid var(--primary-color);
  border-radius: 4px;
}

.atm-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9rem;
  width: 95%;
  margin: 8px auto 0;
  font-weight: 300;
  letter-spacing: 1px;
}

.atm-card-body {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: auto;
  margin-top: 3rem;
}

.atm-card-body h1 {
  font-size: 1rem;
  word-spacing: 1rem;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 0.8rem;
}

.atm-card-body > h2 {
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: 1px;
  margin: 9px 0;
}

.atm-card-body > span {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.atm-card-body > span h2 {
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: 1px;
  word-spacing: 10px;
}

.atm-card-footer {
  position: relative;
}

.atm-card-type {
  width: 5rem;
  height: auto;
  position: absolute;
  right: -1rem;
}
