.coupon-success,
.coupon-error {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 2rem;
  border-radius: 4px;
}
.coupon-error {
  background-color: var(--coupon-error);
}

.coupon-success {
  background-color: var(--coupon-success);
}

.available-coupons {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.6rem;
  margin-top: 1rem;
}

.available-coupons > div {
  display: flex;
  padding: 0.6rem;
}

.applied-coupon {
  background-color: var(--primary-color);
  color: var(-text-white);
  width: 45%;
  padding: 2px 8px;
  border-radius: 4px;
  margin: 8px auto;
}

.applied-coupon p {
  font-size: 0.9rem;
}

.applied-coupon i {
  font-size: 12px;
  padding: 5px;
  cursor: pointer;
}

.available-coupons > div div {
  background-color: var(--available-coupons);
  margin: 0.6rem;
  padding: 0.6rem 1rem;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
}

.available-coupons > div div:hover {
  transition: ease-in 0.2s;
  background-color: var(--primary-color);
}

.cart-coupon-apply input {
  font-size: 0.9rem;
}

.edit-cart-link button {
  margin: 0;
}
