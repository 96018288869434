.card-vertical {
  position: relative;
  margin: 0;
}

.card-vertical > button {
  position: absolute;
  right: 10px;
  top: 10px;
}

.card-cta-btn > button {
  width: 8.2rem;
  height: 2rem;
  font-size: 1rem;
}

.card-dark .badge-on-card {
  height: 2rem;
  width: 7rem;
}

.card-dark .badge-on-card h2 {
  font-size: 1rem;
  color: var(--text-dark);
}

.copied-clipboard {
  position: absolute;
  top: -1.5rem;
  right: 0;
  font-size: 0.9rem;
  background-color: var(--primary-bg);
  border: 1px solid var(--text-light);
  padding: 8px;
  border-radius: 5px;
  z-index: 2;
}

.bump {
  animation: bump 300ms ease-in-out;
}

@keyframes bump {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(0.8);
  }
  30% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  70% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

.alert-animate {
  animation: alert-animate 300ms ease-in;
}

@keyframes alert-animate {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(0.8);
  }
  30% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  70% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}
