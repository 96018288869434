body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Modal */

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.447) !important;
  height: 100% !important;
  width: 100% !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
  position: fixed !important;
  z-index: 4;
}

*::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  width: 10px;
  height: 10px;
  border-radius: 20px;
}

*::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
  width: 10px;
}

@media screen {
}
