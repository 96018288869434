.address-page-container {
  display: flex;
}

.all-address-light,
.all-address-dark {
  width: 100%;
  min-height: 95vh;
  margin: 0;
  padding-top: 2rem;
}

.all-address-light {
  background-color: var(--light-theme-card-bg);
  color: var(--text-dark);
}

.all-address-dark {
  color: var(--text-white);
}

.all-address-light > div,
.all-address-dark > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 1rem auto;
}

.all-address-light .address-title,
.all-address-dark .address-title {
  width: 94%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.all-address-light .address-title h2,
.all-address-dark .address-title h2 {
  font-size: 1rem;
  margin-right: 10px;
}

.address-title h2 {
  font-size: 1.2rem;
  font-weight: 500;
}

.all-address-light .address-title a {
  color: var(--text-dark);
}

.all-address-dark .address-title a {
  color: var(--text-white);
}

@media screen and (max-width: 580px) {
  .all-address-light,
  .all-address-dark {
    min-height: 90vh;
    margin: 3.5rem auto 1rem;
    padding-top: 2rem;
  }

  .all-address-light > div,
  .all-address-dark > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 10px auto;
  }

  .all-address-light .address-title,
  .all-address-dark .address-title {
    width: 94%;
  }

  .all-address-light .address-title h2,
  .all-address-dark .address-title h2 {
    font-size: 1rem;
  }

  .address-title h2 {
    font-size: 1.2rem;
  }
}
