@import url(https://pebblecart-v2.netlify.app/src/style.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Modal */

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.447) !important;
  height: 100% !important;
  width: 100% !important;
  left: 50% !important;
  top: 50% !important;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  position: fixed !important;
  z-index: 4;
}

*::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  width: 10px;
  height: 10px;
  border-radius: 20px;
}

*::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
  width: 10px;
}

@media screen {
}

.camera-animation {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 5;
}

.animation {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 3;
}

.loader-animation {
  background-color: rgba(0, 0, 0, 0.087);
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 5;
}

.payment-success-animation {
  position: relative;
  width: 10rem;
}

.loader-animation > div {
  width: 40rem;
  margin: auto;
  top: 50%;
  position: fixed;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

#camera-dark {
  width: 20rem;
  -webkit-animation: loader 2500ms ease-in-out;
          animation: loader 2500ms ease-in-out;
}

@-webkit-keyframes loader {
  0% {
    opacity: 0.4;
  }
  10% {
    opacity: 0.7;
  }
  20% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes loader {
  0% {
    opacity: 0.4;
  }
  10% {
    opacity: 0.7;
  }
  20% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 450px) {
  .payment-success-animation {
    position: relative;
    width: 10rem;
  }
}

.card-horizontal {
  position: relative;
}

.card-horizontal > button {
  position: absolute;
  left: 10rem;
  top: 1rem;
}

.coupon-success,
.coupon-error {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 2rem;
  border-radius: 4px;
}
.coupon-error {
  background-color: var(--coupon-error);
}

.coupon-success {
  background-color: var(--coupon-success);
}

.available-coupons {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.6rem;
  margin-top: 1rem;
}

.available-coupons > div {
  display: flex;
  padding: 0.6rem;
}

.applied-coupon {
  background-color: var(--primary-color);
  color: var(-text-white);
  width: 45%;
  padding: 2px 8px;
  border-radius: 4px;
  margin: 8px auto;
}

.applied-coupon p {
  font-size: 0.9rem;
}

.applied-coupon i {
  font-size: 12px;
  padding: 5px;
  cursor: pointer;
}

.available-coupons > div div {
  background-color: var(--available-coupons);
  margin: 0.6rem;
  padding: 0.6rem 1rem;
  border-radius: 5px;
  font-size: 0.9rem;
  cursor: pointer;
}

.available-coupons > div div:hover {
  transition: ease-in 0.2s;
  background-color: var(--primary-color);
}

.cart-coupon-apply input {
  font-size: 0.9rem;
}

.edit-cart-link button {
  margin: 0;
}

.payment-btn button {
  width: 100%;
  margin: 10px 0;
}

.no-product-error {
  margin: 1rem;
  color: var(--text-lighter);
  font-size: 1.2rem;
  font-weight: 500;
}

.products {
  width: 95vw;
}

.products > div {
  grid-gap: 1.5rem;
  gap: 1.5rem;
  margin: 0 1rem;
}

@media screen and (max-width: 864px) {
  .products {
    width: 100%;
  }
}

@media screen and (max-width: 580px) {
  .products > div {
    grid-gap: 10px;
    gap: 10px;
  }
}

.nav-bar {
  position: -webkit-sticky;
  position: sticky;
  height: 95vh;
  min-width: 17rem;
  top: 4rem;
  display: flex;
  flex-direction: column;
}

.account-nav-bar-dark,
.account-nav-bar-light {
  padding: 1rem;
  color: var(--text-light);
}

.account-nav-bar-dark {
  color: var(--text-light);
}

.account-nav-bar-light {
  color: var(--text-dark);
}

.user-name {
  margin-top: 1.4rem;
}

.user-name > div {
  width: 2.5rem;
  height: 2.5rem;
  margin-left: 1rem;
}

.user-name,
.nav-orders,
.nav-payment-details,
.nav-addresses,
.nav-support,
.nav-settings {
  display: flex;
  align-items: center;
}

.user-name h1 {
  font-size: 1.1rem;
  margin-left: 10px;
  font-weight: 500;
}

.nav-menu {
  margin-top: 2rem;
}

.nav-menu-title {
  font-weight: 400;
  font-size: 1rem;
}

.account-nav-bar-dark .nav-menu h2,
.account-nav-bar-dark .user-name h1 {
  color: var(--text-light);
}

.account-nav-bar-light .nav-menu h2,
.account-nav-bar-light .user-name h1 {
  color: var(--text-dark);
}

.nav-orders,
.nav-payment-details,
.nav-addresses,
.nav-support,
.nav-settings {
  width: 95%;
  padding: 0.7rem 1rem;
  margin: 10px;
  margin-left: 8px;
}

.nav-orders i,
.nav-payment-details i,
.nav-addresses i,
.nav-support i,
.nav-settings i {
  margin-right: 10px;
  font-size: 1.2rem;
  color: var(--primary-color);
}

.active-nav {
  border-radius: 4px;
  outline: 1px solid var(--primary-color);
  transition: 0.3s ease-in;
}

@media screen and (max-width: 580px) {
  .nav-bar {
    position: fixed;
    z-index: 2;
    width: 18rem;
  }
}

.account-body-dark,
.account-body-light {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
  margin: 1rem auto;
}

.account-body-dark a,
.account-body-light a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10rem;
  width: 15rem;
  margin: 0 auto;
}

.account-body-dark a:hover,
.account-body-light a:hover {
  background-color: var(--primary-color);
  color: var(--text-white);
}

.account-body-light a:hover {
  color: var(--text-dark);
}

.account-body-dark i,
.account-body-light i {
  font-size: 2.5rem;
  color: var(--text-white);
}

.account-body-light i {
  color: var(--text-dark);
}

.account-body-dark h2,
.account-body-light h2 {
  color: var(--text-lighter);
  margin-left: 1.3rem;
  font-weight: 500;
  font-size: 1.2rem;
}

.account-body-light h2 {
  color: var(--text-dark);
}

.account-menu-overview {
  background-color: var(--btn-hover);
  border-radius: 10px;
}

@media screen and (max-width: 580px) {
  .account-body-dark,
  .account-body-light {
    width: 100%;
  }

  .account-body-dark a,
  .account-body-light a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 10rem;
    width: 9.5rem;
    margin: 0 auto;
  }

  .account-body-dark a:hover,
  .account-body-light a:hover {
    background-color: var(--primary-color);
    color: var(--text-white);
  }

  .account-body-light a:hover {
    color: var(--text-dark);
  }

  .account-body-dark i,
  .account-body-light i {
    font-size: 1.4rem;
    color: var(--text-white);
  }

  .account-body-light i {
    color: var(--text-dark);
  }

  .account-body-dark h2,
  .account-body-light h2 {
    color: var(--text-lighter);
    margin: 10px 0 0 0;
    font-weight: 500;
    font-size: 1.2rem;
  }

  .account-body-light h2 {
    color: var(--text-dark);
  }

  .account-menu-overview {
    background-color: var(--btn-hover);
    border-radius: 10px;
  }
}

.user-nav-desktop {
  display: flex;
}

.nav-bar-mobile,
.nav-bar-mobile-light {
  display: none;
}

.nav-bar-mobile {
  background-color: var(--secondary-dark-bg);
  color: var(--text-light);
}

.nav-bar-mobile-light {
  background-color: var(--secondary-light-bg);
  color: var(--text-dark);
}

@media screen and (max-width: 580px) {
  .user-nav-desktop {
    display: none;
  }

  .nav-bar-mobile,
  .nav-bar-mobile-light {
    display: flex;
  }
}

.footer-copyright-text a {
  margin-left: 5px;
}

.copyright-text {
  color: var(--title-color);
}

@media screen and (max-width: 500px) {
  .recommended-banner div p {
    font-size: 1.2rem;
  }

  .footer-main-area {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.create-account-btn h2 {
  font-size: 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-account-btn:hover {
  cursor: pointer;
}

.signin-modal form {
  text-align: center;
}

.remember-me {
  display: grid;
  grid-template-columns: 2rem 6rem;
  flex-direction: row;
  align-items: center;
  width: 100%;
  text-align: left;
  margin: 10px 0;
}

.remember-me input,
.remember-me label {
  cursor: pointer;
}

.remember-me input {
  height: 1rem;
}

.signin-modal form p span {
  color: var(--primary-color);
  margin-left: 1rem;
}

.signin-modal .password-reset span {
  cursor: pointer;
}

.password-input-field i:nth-child(3) {
  left: 100%;
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
  cursor: pointer;
}

.guest-user-btn {
  width: 100%;
}

.guest-user-btn button {
  margin: 0 0 10px;
  width: 100%;
}

.existing-account-btn {
  text-align: center;
  cursor: pointer;
}

.existing-account-btn h2 {
  font-size: 1rem;
  font-weight: 400;
  margin-top: 10px;
  color: var(--text-light);
}

.existing-account-btn span {
  padding: 0 10px;
  font-weight: bold;
  color: var(--primary-color);
}

.desktop-navigation-bar {
  box-shadow: 0 1px 4px var(--navbar-shadow);
}

.profile-hover-menu {
  background-color: var(--primary-color);
  width: 8.7rem;
  position: absolute;
  top: 4rem;
  right: 2.5rem;
  border-radius: 4px;
  padding: 12px;
  z-index: 50;
}

.profile-hover-menu h2 {
  font-size: 1rem;
  text-align: center;
  margin: 5px 0;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
  padding: 5px 0;
  border: 1px solid transparent;
  color: var(--text-white);
}

.profile-hover-menu h2:hover {
  border: 1px solid var(--text-white);
  border-radius: 4px;
}

.mobile-navigation-bar {
  box-shadow: 0 1px 4px var(--navbar-shadow);
}

.profile-theme-toggle {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 580px) {
  .hamburger {
    position: static;
  }
}

.bodyWrapper {
  min-height: 95vh;
}

.address-dark {
  background-color: var(--dark-theme-card-hover);
}

.address-light {
  background-color: var(--light-theme-card-hover);
}

.address-light,
.address-dark {
  width: 95%;
  margin: 0 auto;
  text-align: left;
  border-radius: 4px;
}

.address-light .address-text,
.address-dark .address-text {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
}

.address-cta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid var(--btn-hover);
}

.address-cta-edit {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.address-cta-edit button {
  font-weight: 500;
}

.address-page-container {
  display: flex;
}

.all-address-light,
.all-address-dark {
  width: 100%;
  min-height: 95vh;
  margin: 0;
  padding-top: 2rem;
}

.all-address-light {
  background-color: var(--light-theme-card-bg);
  color: var(--text-dark);
}

.all-address-dark {
  color: var(--text-white);
}

.all-address-light > div,
.all-address-dark > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 1rem auto;
}

.all-address-light .address-title,
.all-address-dark .address-title {
  width: 94%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.all-address-light .address-title h2,
.all-address-dark .address-title h2 {
  font-size: 1rem;
  margin-right: 10px;
}

.address-title h2 {
  font-size: 1.2rem;
  font-weight: 500;
}

.all-address-light .address-title a {
  color: var(--text-dark);
}

.all-address-dark .address-title a {
  color: var(--text-white);
}

@media screen and (max-width: 580px) {
  .all-address-light,
  .all-address-dark {
    min-height: 90vh;
    margin: 3.5rem auto 1rem;
    padding-top: 2rem;
  }

  .all-address-light > div,
  .all-address-dark > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 10px auto;
  }

  .all-address-light .address-title,
  .all-address-dark .address-title {
    width: 94%;
  }

  .all-address-light .address-title h2,
  .all-address-dark .address-title h2 {
    font-size: 1rem;
  }

  .address-title h2 {
    font-size: 1.2rem;
  }
}

.new-address h2 {
  font-size: 1rem;
  font-weight: 500;
}

.new-address {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem 0;
  cursor: pointer;
}

.new-address i {
  margin-right: 1rem;
  font-size: 1.2rem;
  color: var(--primary-color);
}

.address-modal {
  position: fixed;
  left: 0;
  top: 0;
}

.address-modal-close-btn {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.orders-page-container {
  display: flex;
}

.ordered-product-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  color: var(--text-lighter);
}

.order-card-top-section,
.order-card-top-section-light,
.order-card-bottom-section,
.order-card-bottom-section-light {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 95%;
  padding: 1rem;
  border-radius: 4px;
  background-color: var(--dark-theme-card-bg);
  border-top: 1px solid var(--btn-hover);
  margin: auto;
}

@media screen and (max-width: 500px) {
  .order-card-top-section,
  .order-card-top-section-light,
  .order-card-bottom-section,
  .order-card-bottom-section-light {
    flex-direction: column;
  }
}

.order-card-image-description {
  display: flex;
}

.order-card-top-section img,
.order-card-top-section-light img {
  width: 7rem;
  height: auto;
  object-fit: contain;
  border-radius: 4px;
  margin-right: 1rem;
}

.ordered-product-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: auto;
  margin-right: 1rem;
}

.ordered-product-details p {
  word-wrap: break-word;
  height: 3.5rem;
  overflow: hidden;
}

.product-reorder-btn {
  margin: 6px 0 0 0;
  padding: 5px 20px;
}

@media screen and (max-width: 500px) {
  .order-card-image-description {
    margin-bottom: 10px;
  }

  .ordered-product-details {
    margin-right: 0;
  }

  .product-reorder-btn {
    margin: 10px 0;
  }

  .ordered-product-delivery {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.order-card-bottom-section {
  background-color: var(--dark-theme-card-hover);
  padding: 10px 1rem;
}

.product-track-order-btn {
  margin: 0 1rem 0 0;
  height: 2.8rem;
}

.order-card-bottom-left,
.order-card-bottom-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-card-date-section {
  width: 6rem;
  margin-right: 2rem;
}

.order-card-date-section p,
.order-card-details-section p {
  margin: 5px 0;
}

.ordered-product-delivery-details,
.order-card-total-price {
  min-width: 10rem;
}

.order-card-total-price p {
  margin: 5px 0;
  text-align: left;
}

.ordered-product-delivery-details p {
  font-size: 1rem;
}

@media screen and (max-width: 500px) {
  .order-card-bottom-left {
    width: 100%;
    margin-bottom: 10px;
  }

  .order-card-date-section {
    margin-right: 0;
  }
}

.payment-page-container {
  display: flex;
}

.payment-page-light > div {
  background-color: var(--light-theme-card-bg);
  color: var(--text-dark);
}

.payment-cards-detail {
  color: var(--text-white);
}

.all-address-dark .payment-cards-container,
.all-address-dark .payment-upi-container {
  background-color: var(--dark-theme-card-hover);
}

.all-address-light .payment-cards-container,
.all-address-light .payment-upi-container {
  background-color: var(--light-theme-card-hover);
}

.payment-cards-container,
.payment-upi-container {
  width: 95%;
  border-radius: 4px;
  padding: 1rem 1rem 10px;
  display: flex;
  flex-direction: column;
}

.payment-upi-container {
  padding: 1rem 2rem 10px;
}

.payment-cards-title,
.payment-upi-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 98%;
}

.payment-cards-title div,
.payment-upi-title div {
  display: flex;
  align-items: center;
}

.payment-cards-title h2,
.payment-upi-title h2 {
  font-size: 1rem;
  font-weight: 400;
}

.payment-cards-title button,
.payment-upi-title button {
  margin-left: 1rem;
}

.payment-cards-detail {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem auto 1rem 0;
  overflow-x: scroll;
}

.payment-cards-detail::-webkit-scrollbar {
  display: none;
}

.payment-upi-detail {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 98%;
  font-weight: 400;
  letter-spacing: 1px;
}

.payment-upi-detail li {
  margin: 6px 0;
}

.atm-card-wrapper {
  width: 19rem;
  height: 12rem;
  border-radius: 10px;
  padding: 10px;
  overflow: hidden;
  margin: 10px;
  position: relative;
}

.atm-card-wrapper::-webkit-scrollbar {
  display: none;
}

.atm-card-wrapper button {
  visibility: hidden;
  padding: 5px 0.8rem;
  position: absolute;
  z-index: 2;
  top: 8px;
  right: 6px;
  border-radius: 50%;
  background-color: var(--payment-card-hover);
}

.atm-card-wrapper button:hover {
  background-color: var(--light-gray);
}

.atm-card-wrapper:hover button {
  visibility: visible;
}

.card-option-menu {
  right: 1rem;
  top: 3rem;
  width: 7rem;
  height: auto;
  border-radius: 4px;
  color: var(--text-dark);
  background-color: var(--primary-bg);
  position: absolute;
  z-index: 2;
}

.card-option-menu h2 {
  font-size: 0.9rem;
  font-weight: 500;
  text-align: center;
  margin: 5px;
  padding: 4px;
  cursor: pointer;
}

.card-option-menu h2:hover {
  outline: 1px solid var(--primary-color);
  border-radius: 4px;
}

.atm-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9rem;
  width: 95%;
  margin: 8px auto 0;
  font-weight: 300;
  letter-spacing: 1px;
}

.atm-card-body {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: auto;
  margin-top: 3rem;
}

.atm-card-body h1 {
  font-size: 1rem;
  word-spacing: 1rem;
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 0.8rem;
}

.atm-card-body > h2 {
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: 1px;
  margin: 9px 0;
}

.atm-card-body > span {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.atm-card-body > span h2 {
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: 1px;
  word-spacing: 10px;
}

.atm-card-footer {
  position: relative;
}

.atm-card-type {
  width: 5rem;
  height: auto;
  position: absolute;
  right: -1rem;
}

.settings-page-container {
  display: flex;
}

.setting-edit-container,
.setting-save-buttons {
  width: 95%;
  border-radius: 4px;
  padding: 1rem 1rem 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.setting-save-buttons {
  flex-direction: row !important;
  justify-content: flex-end !important;
}

.setting-save-buttons button {
  margin: 0 10px;
}

.all-address-dark .setting-edit-container {
  background-color: var(--dark-theme-card-hover);
}

.all-address-light .setting-edit-container {
  background-color: var(--light-theme-card-hover);
}

.setting-title {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 98%;
}

.setting-edit-container h2 {
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  margin: 5px 0;
}

.setting-edit-container .no-outline-password-input {
  width: 20rem;
  margin-left: 0;
}

.setting-title-content {
  width: 98%;
  display: flex;
}

.support-page-container {
  display: flex;
}

.search-container {
  margin: 0.5rem 0.6rem 0.5rem 1rem;
  width: 60%;
  height: 2.3rem;
  border: 1px solid var(--dark-blue-gray);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-container input {
  width: 100%;
  height: 2.1rem;
  margin: 0.4rem auto 0.4rem;
  margin-left: 0.6rem;
  font-size: 1rem;
  background-color: transparent;
  border: none;
}

.search-container input:focus {
  outline: none;
}

.search-container i {
  font-size: 1.2rem;
  padding: 0.6rem;
  margin: 0 0.6rem;
  color: var(--primary-color);
}

.price-slider-title span {
  font-size: 0.9rem;
}

.price-slider-label-dark,
.price-slider-label-light {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
  justify-content: space-around;
  margin-top: 1rem;
}

.price-range-input input {
  width: 12rem;
  margin: 10px auto;
  display: flex;
}

.price-range {
  margin: 10px auto 0;
}

.price-slider-label-dark .price-range {
  color: var(--title-color);
}

.price-slider-label-light .price-range {
  color: var(--light-theme-title-color);
}

.rating-slider-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
  justify-content: space-around;
  margin-top: 1rem;
}

.rating .range-input {
  width: 12rem;
  margin: 10px auto;
}

.card-vertical {
  position: relative;
  margin: 0;
}

.card-vertical > button {
  position: absolute;
  right: 10px;
  top: 10px;
}

.card-cta-btn > button {
  width: 8.2rem;
  height: 2rem;
  font-size: 1rem;
}

.card-dark .badge-on-card {
  height: 2rem;
  width: 7rem;
}

.card-dark .badge-on-card h2 {
  font-size: 1rem;
  color: var(--text-dark);
}

.copied-clipboard {
  position: absolute;
  top: -1.5rem;
  right: 0;
  font-size: 0.9rem;
  background-color: var(--primary-bg);
  border: 1px solid var(--text-light);
  padding: 8px;
  border-radius: 5px;
  z-index: 2;
}

.bump {
  -webkit-animation: bump 300ms ease-in-out;
          animation: bump 300ms ease-in-out;
}

@-webkit-keyframes bump {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  20% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  70% {
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes bump {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  20% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  70% {
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.alert-animate {
  -webkit-animation: alert-animate 300ms ease-in;
          animation: alert-animate 300ms ease-in;
}

@-webkit-keyframes alert-animate {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  20% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  70% {
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes alert-animate {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  20% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  30% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  70% {
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.inActivePayment {
  -webkit-filter: grayscale(100) brightness(0.5);
          filter: grayscale(100) brightness(0.5);
}

.contact-address details > button {
  margin-left: 5px;
}

.checkout-address-section {
  display: flex;
}

.checkout-address-section {
  position: relative;
  cursor: pointer;
}

.address input {
  position: absolute;
  right: -1rem;
  top: 1rem;
}

.address {
  align-items: flex-start;
}

.checkout-address-container {
  display: flex;
  flex-wrap: wrap;
}

.contact-address .address-dark,
.contact-address .address-light {
  width: 18rem;
  margin: 10px;
}

.new-address-button {
  margin-left: 10px !important;
}

.payment-confirm-wrapper,
.payment-confirm-wrapper-light {
  background-color: var(--dark-payment-confirm-bg);
  color: var(--title-color);
  border-radius: 10px;
  padding: 0 3rem 3rem;
  max-width: 35rem;
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 4;
  width: 100% !important;
}

.close-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.3rem;
}

.payment-confirm-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment-confirm-title {
  font-size: 1.6rem;
  margin: -1.2rem 0 1rem;
  text-align: center;
  font-weight: 500;
}

.payment-confirm-message {
  font-size: 1.1rem;
  font-weight: 400;
  margin-bottom: 1rem;
  text-align: center;
}

.orderId,
.paymentId {
  margin: 5px 0;
  font-size: 1.1rem;
}

.payment-confirm-cta {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 2rem;
}

.payment-confirm-cta button {
  width: 100%;
  margin: 0;
}

@media screen and (max-width: 450px) {
  .payment-confirm-wrapper {
    padding: 0 1rem 2.5rem;
  }

  .payment-confirm-title {
    font-size: 1.3rem;
  }

  .payment-confirm-message {
    font-size: 1.1rem;
  }

  .orderId,
  .paymentId {
    font-size: 1.1rem;
  }

  .payment-confirm-cta {
    margin-top: 1.5rem;
  }
}

.emptyWishlistMsg {
  color: var(--text-lighter);
  text-align: center;
  position: absolute;
  left: 50%;
  top: 30%;
  -webkit-transform: translate(-50%, -20%);
          transform: translate(-50%, -20%);
}

.emptyWishlistMsg h1 {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin: auto;
  width: 100%;
  height: 100vh;
  background-color: var(--dark-bg-color);
  color: var(--title-color);
}

.error-number {
  display: flex;
}

.error-number h1:nth-child(1) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
}

.error-number h1:nth-child(3) {
  -webkit-transform: rotate(80deg);
          transform: rotate(80deg);
  margin-top: 4rem;
  font-size: 10rem;
}

.page-not-found h1 {
  font-size: 8rem;
  letter-spacing: 2rem;
  background: transparent;
}



.account-page-main {
  display: flex;
  margin: 0 auto;
}

.my-account-section {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: auto;
  margin-top: 2rem;
}

.my-account-section h1 {
  font-weight: 500;
}

.my-account-title-dark,
.my-account-title-light {
  font-size: 1.4rem;
}

.my-account-title-dark {
  color: var(--text-lighter);
}

.my-account-title-light {
  color: var(--text-dark);
}

:root {
  /* dark mode */
  --dark-bg-color: #141517;
  --secondary-dark-bg: #18191a;
  --dark-theme-card-bg: #1a1b1c;
  --dark-theme-card-hover: rgba(40, 40, 40, 0.666);
  --title-color: #e9e9e9;
  --sub-title-color: #cecece;
  --payment-card-hover: rgb(176, 176, 176);
  --coupon-error: rgba(252, 0, 0, 0.18);
  --coupon-success: rgba(34, 252, 0, 0.18);
  --available-coupons: rgba(97, 97, 97, 0.139);
  --navbar-shadow: rgba(0, 0, 0, 0.164);
  --dark-payment-confirm-bg: rgb(57, 57, 57);

  /* light mode */
  --light-bg-color: #f1f1f1;
  --secondary-light-bg: #e9e9e9;
  --item-overview-light-bg: #f0f0f0;
  --light-theme-category-card: #24242454;
  --light-theme-card-bg: #f2f2f2;
  --light-theme-card-hover: rgba(106, 106, 106, 0.123);
  --light-theme-title-color: #252525;
  --light-theme-sub-title-color: #484848;
  --payment-card-hover: rgb(176, 176, 176);
  --banner-square-card-light: rgb(121, 146, 176);
  --order-details-bg: rgb(201, 201, 201);
  --light-payment-confirm-bg: rgb(255, 255, 255);
}

body.lightTheme {
  background-color: #f1f1f1;
  background-color: var(--light-bg-color);
  color: #252525;
  color: var(--light-theme-title-color);
}

.app {
  -webkit-animation: app 200ms ease-in-out;
          animation: app 200ms ease-in-out;
}

@-webkit-keyframes app {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.4;
  }
  40% {
    opacity: 0.8;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes app {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.4;
  }
  40% {
    opacity: 0.8;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.single-product {
  margin-bottom: 2rem;
}

.single-product-image {
  position: relative;
}

.single-product-image > button {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 1.4rem;
  padding: 10px;
  border-radius: 50%;
}

.product-description-text {
  font-size: 1.2rem;
  word-spacing: 2px;
  line-height: 26px;
  margin-bottom: 7px;
}

.rating-star-container input {
  width: 9px;
}

.notLoggedIn {
  font-size: 1.4rem;
  font-weight: 400;
  margin-top: 5rem;
  padding: 1.5rem;
  text-align: center;
  color: var(--text-white);
}

.checkout-empty-cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.checkout-empty-cart h2 {
  margin: 1rem 0;
  font-size: 1.2rem;
  margin-top: 2rem;
  font-weight: 500;
}

/* light theme start */

body.lightTheme {
  background-color: #f1f1f1;
  background-color: var(--light-bg-color);
  color: #252525;
  color: var(--light-theme-title-color);
}

.mobile-navigation-bar-bottom-light {
  background-color: var(--primary-bg);
}

.hero-section-light,
.landing-recommended-section-light,
.footer-light {
  background-color: #f1f1f1;
  background-color: var(--light-bg-color);
}

.hero-banner-light h1,
.recommended-banner-light h1 {
  color: var(--text-dark);
}

.hero-banner-light > div > p,
.recommended-banner-light > div > p {
  color: var(--text-dark);
}

.hero-section-light .card-vertical-info-light {
  background-color: rgb(121, 146, 176);
  background-color: var(--banner-square-card-light);
}

.hero-section-light .card-vertical-info-light:hover {
  background-color: rgba(40, 40, 40, 0.666);
  background-color: var(--dark-theme-card-hover);
}

.hero-categories-light > a > div:hover {
  background-color: rgba(106, 106, 106, 0.123);
  background-color: var(--light-theme-card-hover);
}

.hot-new-products-light,
.footer-light {
  background-color: #e9e9e9;
  background-color: var(--secondary-light-bg);
}

.hot-new-products-light > h1 {
  color: var(--text-dark);
}

.hot-new-products-light .hot-new-slider-dots > i {
  color: var(--text-dark);
}

.filter-section-light > div button {
  color: #252525;
  color: var(--light-theme-title-color);
}

.filter-section-light h2,
.products-light > h1,
.product-details-header-light,
.product-details-body-light,
.single-product-description-light,
.card-text-light h1 {
  color: #252525;
  color: var(--light-theme-title-color);
}

.filter-section-light label,
.filter-section-light p {
  color: #484848;
  color: var(--light-theme-sub-title-color);
}

.products-light > div > div:hover,
.wishlist-light > div > div:hover,
.cart-products-light > div:hover,
.similar-products-light > div > div:hover {
  background-color: rgba(106, 106, 106, 0.123);
  background-color: var(--light-theme-card-hover);
}

.product-details-feature-light {
  color: var(--text-dark);
}

.rating-badge-light {
  color: var(--text-dark);
}

.cart-light > h1,
.wishlist-light > h1,
.similar-products-light > h1,
.copyright-text-light {
  color: #252525;
  color: var(--light-theme-title-color);
}

.hero-categories-light > a > div,
.products-light > div > div,
.wishlist-light > div > div,
.cart-item-card-light,
.similar-products-light > div > div {
  background-color: #f2f2f2;
  background-color: var(--light-theme-card-bg);
}

.checkout-item-card-light {
  background-color: var(--primary-bg);
  border: none;
}

.cart-item-card-light {
  background-color: var(--primary-bg);
}

.cart-item-card-light:hover {
  background-color: transparent;
}

#products-light .card-dark .card-text-light .card-price,
.cart-item-card-light .card-price,
.cart-products-light .card-price {
  color: var(--text-red);
  font-weight: 500;
}

.checkout-price-table {
  border: none;
}

.price-table-light p {
  color: var(--text-dark);
}

.contact-address-light,
.payment-method-light,
.checkout-item-overview-light {
  background-color: var(--primary-bg);
  color: #484848;
  color: var(--light-theme-sub-title-color);
}

.contact-address-light .address label {
  color: #484848;
  color: var(--light-theme-sub-title-color);
  font-size: 1rem;
}

.contact-address-light button {
  color: var(--text-dark);
  font-weight: bold;
}

.checkout-item-overview-light:hover,
.contact-address-light:hover,
.payment-method-light:hover,
.order-card-top-section:hover,
.order-card-bottom-section:hover {
  background-color: rgba(106, 106, 106, 0.123);
  background-color: var(--light-theme-card-hover);
}

.order-card-top-section-light {
  background-color: rgba(106, 106, 106, 0.123);
  background-color: var(--light-theme-card-hover);
  color: var(--text-dark);
}

.order-card-bottom-section-light {
  color: var(--text-dark);
  background-color: rgb(201, 201, 201);
  background-color: var(--order-details-bg);
}

.checkout-item-card .card-light {
  color: #484848;
  color: var(--light-theme-sub-title-color);
}

.payment-confirm-wrapper-light {
  background-color: rgb(255, 255, 255);
  background-color: var(--light-payment-confirm-bg);
  color: #484848;
  color: var(--light-theme-sub-title-color);
}

.break-line-light,
.section-break-line-light {
  border-bottom: 1px solid var(--text-dark);
}

.footer-light .footer-main-area,
.footer-light .footer-main-area a {
  color: #484848;
  color: var(--light-theme-sub-title-color);
}

.footer-light .footer-connect-icons-light i:hover {
  background-color: rgba(106, 106, 106, 0.123);
  background-color: var(--light-theme-card-hover);
}

.footer-light .footer-copyright-text-light {
  color: var(--text-dark);
}

.card-light h1,
.card-light .rating-badge-number {
  color: var(--text-dark);
}

/* card img size */
.card-vertical img,
.card-horizontal img,
.cart-item-card img {
  min-width: 9.5rem;
  height: 8rem;
  object-fit: contain;
}

.card-horizontal img,
.cart-item-card img {
  width: 8rem;
}

.card-vertical-info .card-img-container {
  height: 6.5rem;
}

.filter-section {
  width: 16.8rem;
}

.single-product-image .main-image {
  width: 19rem;
  height: 19rem;
  object-fit: contain;
}

.single-product-image .main-image:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  transition: 200ms ease-in;
  cursor: pointer;
}

.single-product-image-options img {
  width: 4rem;
  margin: 12px;
  cursor: pointer;
}

@media screen and (max-width: 1050px) {
  .filter-section {
    padding-top: 0;
  }

  .single-product-image .main-image {
    width: 15rem;
    height: auto;
    object-fit: contain;
  }
}

@media screen and (max-width: 864px) {
  .filter-section {
    background-color: #1a1b1c;
    background-color: var(--dark-theme-card-bg);
  }
  .filter-section-light {
    background-color: #f2f2f2;
    background-color: var(--light-theme-card-bg);
  }
}

@media screen and (max-width: 580px) {
  .hero-banner {
    margin: 3.5rem auto 2rem;
  }

  .hero-banner img {
    margin: 3rem auto 2rem;
  }

  .card-vertical img,
  .card-horizontal img,
  .cart-item-card img {
    width: 9.5rem;
    height: 8rem;
    object-fit: contain;
  }

  .card-horizontal img,
  .cart-item-card img {
    width: 9rem;
  }

  .card-vertical .card-nav {
    width: 100%;
  }

  .card-vertical .card-cta-btn {
    justify-content: space-between;
    min-width: 100%;
  }

  .single-product-detail {
    margin-top: 0;
  }

  .product-details-body {
    margin-top: 0;
  }

  .single-product-image {
    margin-bottom: 0;
  }

  .single-product-image .main-image {
    width: 14rem;
    height: 20rem;
    margin: auto;
    object-fit: contain;
  }
}

@media screen and (max-width: 400px) {
  .hero-banner {
    margin: 7rem auto 0;
  }

  .hero-banner img {
    margin: 3rem auto 2rem;
  }
}

