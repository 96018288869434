.user-nav-desktop {
  display: flex;
}

.nav-bar-mobile,
.nav-bar-mobile-light {
  display: none;
}

.nav-bar-mobile {
  background-color: var(--secondary-dark-bg);
  color: var(--text-light);
}

.nav-bar-mobile-light {
  background-color: var(--secondary-light-bg);
  color: var(--text-dark);
}

@media screen and (max-width: 580px) {
  .user-nav-desktop {
    display: none;
  }

  .nav-bar-mobile,
  .nav-bar-mobile-light {
    display: flex;
  }
}
