.mobile-navigation-bar {
  box-shadow: 0 1px 4px var(--navbar-shadow);
}

.profile-theme-toggle {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 580px) {
  .hamburger {
    position: static;
  }
}
